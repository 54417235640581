export const STATUS_PAGE_LINK = 'https://status.gitlab.com';
export const CONTACT_SUPPORT_LINK = 'https://about.gitlab.com/support/';
export const CONTACT_US_PATH = '/contact_us';
export const BILLING_ACCOUNT_PATH = '/billing_accounts';
// Deprecated, use CONTACT_SUPPORT_PLACEHOLDER
// Clean up in https://gitlab.com/gitlab-org/customers-gitlab-com/-/issues/7669
export const DEPRECATED_CONTACT_SUPPORT_PLACEHOLDER = '%{linkStart}contact support%{linkEnd}';

// Allows error string to contain multiple links by more clearly "namespacing" the link placeholder
export const CONTACT_SUPPORT_PLACEHOLDER = '%{supportLinkStart}contact support%{supportLinkEnd}';

export const ERROR_FETCHING_DATA_HEADER = 'Could not get the data properly';
export const ERROR_FETCHING_DATA_DESCRIPTION =
  'Please try to refresh the page. If the problem persists, contact support.';
export const ERROR_FETCHING_DATA = `Data could not be retrieved. Please try again. If the problem persists, ${DEPRECATED_CONTACT_SUPPORT_PLACEHOLDER}.`;
export const ERROR_PROCESSING_PAYMENT = `Payment could not be processed. Please try again. If the problem persists, ${DEPRECATED_CONTACT_SUPPORT_PLACEHOLDER}.`;
export const GENERAL_ERROR_MESSAGE =
  'Unknown error occurred, this has been logged in our service. Open a Support ticket if the problem persists.';

export const UNEXPECTED_ERROR_MESSAGE = `An unexpected error occurred. If the problem persists, ${CONTACT_SUPPORT_PLACEHOLDER}.`;
export const ERROR_CODE_MAX_SEATS_EXCEEDED = 'MaxAdditionalSeatsExceededError';
export const ERROR_CODE_ZUORA_LOCK = 58730050;
export const DEFAULT_ERROR_CODE = 'NOT_PROVIDED';

export const GITLAB_EE_PLAN = 'gitlab-ee-plan';
export const GITLAB_COM_PLAN = 'gitlab-com-plan';
export const USD_SYMBOL = '$';
export const MIN_VALIDATION_MESSAGE = `Enter a number greater than or equal to %{minValue}`;
export const MIN_SEATS_REASON = 'seats currently in use';

export const OVERAGE_BILLING_LINK =
  'https://docs.gitlab.com/subscriptions/quarterly_reconciliation/';

export const ROLES_AND_PERMISSIONS_LINK = 'https://docs.gitlab.com/user/permissions/#roles';

export const GITLAB_PASSWORD_EDIT_URL = 'https://gitlab.com/-/profile/password/edit';

export const COUNTRIES_WITH_STATES_REQUIRED = ['USA', 'CAN'];
export const COUNTRIES_TO_HIDE_TAX_ID = ['USA'];

export const SOLD_TO_CONTACT = 'soldToContact';
export const BILL_TO_CONTACT = 'billToContact';

export const DEFAULT_DEBOUNCE_AND_THROTTLE_MS = 250;
export const ERROR_CODE_REQUEST_CANCELED = 'ERR_CANCELED';
